<template>
  <template-login>
    <header class="mb-6 text-center">
      <px-icon
        name="brand-icon-mail-open.png"
        class="w-btn mx-auto mb-3 md:mb-2"
        alt="Ícone email aberto"
      />
      <h1 class="font-bold text-xl mb-3 md:mb-2">Verificação de email</h1>
      <p class="text-gray-500">
        Digite o código de {{ inputCodeLength }} dígitos enviado para
      </p>
      <p class="font-semibold text-gray-500">{{ username }}</p>
    </header>
    <px-input-code
      :length="inputCodeLength"
      :status="status"
      @complete="handleInputComplete"
    />

    <!-- <div class="text-center mb-12">
      <px-button
        kind="primary-plain"
        :disabled="!timerComplete"
        @click="resendSecurityCode"
      >
        Enviar código novamente
        <span v-show="!timerComplete">({{ timerCountSeconds }}s)</span>
      </px-button>
    </div> -->
    <!-- <footer class="text-center">
      <div class="text-center mt-1 text-gray-900">
        Email errado?
        <router-link to="/alterar-email" class="text-gray-900 hover:underline">
          Trocar endereço de email.
        </router-link>
      </div>
    </footer> -->
    <template #below-card>
      <div class="text-center">
        <router-link to="/login" class="text-white hover:underline">
          Voltar para o login
        </router-link>
      </div>
    </template>
  </template-login>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TemplateLogin from './TemplateLogin.vue';
import {
  // PxButton,
  PxIcon,
  PxInputCode,
} from '../../../components/custom';

const inputStatusEnum = {
  loading: 'loading',
  success: 'success',
  failure: 'failure',
};

export default {
  name: 'authenticate',
  metaInfo: {
    title: 'Verificação de email',
  },
  components: {
    TemplateLogin,
    // PxButton,
    PxIcon,
    PxInputCode,
  },
  data: () => ({
    inputCodeLength: 4,
    timerComplete: false,
    timerCountSeconds: 30,
    timerInterval: null,
    timerSeconds: 30,
  }),
  computed: {
    ...mapState('auth', ['verifyStatus', 'username']),
    status() {
      if (this.verifyStatus === 'verifyWait') return inputStatusEnum.loading;
      if (this.verifyStatus === 'verifySuccess') return inputStatusEnum.success;
      if (this.verifyStatus === 'verifyFailure') return inputStatusEnum.failure;
      return '';
    },
  },
  methods: {
    ...mapActions('auth', ['verify']),
    handleInputComplete(value) {
      this.verify({ securityCode: value });
    },
    resendSecurityCode() {
      this.startTimer();
    },
    finishTimer() {
      clearInterval(this.timerInterval);
      this.timerInterval = null;
    },
    startTimer() {
      this.timerCountSeconds = this.timerSeconds;
      this.timerInterval = setInterval(() => {
        this.timerCountSeconds -= 1;
      }, 1000);
    },
  },
  watch: {
    timerCountSeconds: {
      handler(time) {
        if (time <= 0) {
          this.timerComplete = true;
          this.finishTimer();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.startTimer();
  },
};
</script>
