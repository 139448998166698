<template>
  <div class="hero-pattern min-h-full flex flex-col justify-between items-center">
    <header class="pt-9 pb-3 lg:pb-9">
      <div class="flex items-center">
        <img src="https://cdn.parcelex.com.br/assets/v3/logos/parcelex-centrax-white.png" class="hidden md:inline-block h-8" alt="Logo Parcelex"/>
        <img src="https://cdn.parcelex.com.br/assets/v3/logos/parcelex-centrax-white-vertical.svg" class="md:hidden h-13" alt="Logo Parcelex"/>
      </div>
    </header>
    <div class="flex-shrink-0 p-6 lg:p-0 w-full md:max-w-md">
      <div class="p-6 lg:p-9 bg-white rounded-2xl mb-5 shadow-lg">
        <slot/>
      </div>
      <slot name="below-card"/>
    </div>
    <footer class="text-center text-primary-500 pt-4 pb-6">
      &copy; Copyright {{ year }} Parcelex.
    </footer>
  </div>
</template>

<script>
import moment from '../../../lib/moment-ptbr';

const DAY_DURATION_MILLISECONDS = 24 * 60 * 60 * 1000;

export default {
  name: 'template-login',
  data: () => ({
    year: moment().format('YYYY'),
  }),
  methods: {
    updateYear() {
      setInterval(() => {
        this.year = moment().format('YYYY');
      }, DAY_DURATION_MILLISECONDS);
    },
  },
};
</script>
